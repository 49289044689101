import styled from 'styled-components'

export default styled.ul`
  margin-left: 0;
  padding-left: 0;

  li {
    list-style: none;
  }
`
